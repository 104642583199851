<template>
  <v-btn
    v-bind="$attrs"
    role="button"
  >
    <slot />

    <template v-slot:loader>
      <v-progress-linear
        color="accent"
        height="10"
        indeterminate
        class="mx-10"
        striped
      ></v-progress-linear>
    </template>
  </v-btn>
</template>
